@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.error-message {
    color: red;
}

.flex {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
}

.col {
    flex-direction: column;
}

.gap-10 {
    gap: 10px;
}

.w-100 {
    min-width: 100%;
    max-width: 100%;
}

.p-10 {
    padding: 10px;
}



.w-250 {
    width: 250px;
}

// refactor


$sidebar-width: 270px;
$sidebar-colour: #2B3E51;
$header-height: 115px;
$off-white: #FBFBFB;
$secondary-text: #5A5C6C;
$primary-text: #27283A;
$field: #FBFBFB;
$field-stroke: #e9e9e9;
$border: #E7E7E7; 
$background: #FBFBFB;
$dark-blue: #003054;
$blue: #6997EE;
$gold: #FCB529;
$red: #FF5353;
$green: #00AB65;
$objective-width: 270px;
$light-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);

* {
    box-sizing: border-box;
    margin: 0px;
    
    //Default font styling
    color: $secondary-text;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

#root {
    height: 100vh;
}

button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    outline: none;

    &:focus, &:hover {
        outline: none;
        border: none;
    }
}

a {
    text-decoration: none;
}

p {
    overflow-wrap: break-word;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
  
::-webkit-scrollbar-track {
    margin: 5px 5px;
    background: transparent;
}

::-webkit-scrollbar-thumb {

background: rgb(190, 190, 190);
border-radius: 50px;

}

%text-overflow {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu {
    position: absolute;
    z-index: 9999;
}

.sidebar {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;

    height: 100%;
    max-width: $sidebar-width;
    min-width: $sidebar-width;
    background-color: $sidebar-colour;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 7px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #616385;
        border-radius: 50px;
    }

    &__navigation-item {
        padding: 0px 20px;
        display: flex;
        align-items: center;
        gap: 15px;

        color: $off-white;

        svg {
            stroke: $off-white;
        }
    }

    &__logo-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        gap: 15px;
        padding: 20px;

        &__minimise-button {
            display: flex;
            align-items: center;

            svg {
                stroke: $off-white;
            }
        }
    }

    &__navigation-section {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px;
    }

    &__account-section {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px;

        &__logout {
            @extend .sidebar__navigation-item;
            color: $red;

            svg {
                stroke: $red;
            }
        }
    }

}

.workspaces {
    
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__new-button {
        background-color: $blue;
        color: $off-white;
        padding: 10px 20px 10px 20px;
        border-radius: 5px;

        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            stroke: $off-white;
        }

    }

    &__show-button {
        background-color: #21303F;
        padding: 10px 20px 10px 20px;
        border-radius: 5px;

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        svg {
            stroke: $off-white;
        }

        &__span {
            display: flex;
            align-items: center;
            gap: 10px;
            color: $off-white;
        }

        &__arrow--up {
            transform: rotate(180deg);
        }
    }

    &__list {

        max-height: 250px;
        overflow-y: scroll;

        list-style: none;
        padding: 0px 20px;

        &::-webkit-scrollbar {
            width: 7px;
        }
        
        &::-webkit-scrollbar-thumb {
            background: #616385;
            border-radius: 50px;
        }

        &__item {        
            width: 100%;
            margin-bottom: 15px;

            &:nth-child(4n-7) &__point {
                background-color: $gold;
            }

            &:nth-child(4n-6) &__point {
                background-color: $green;
            }

            &:nth-child(4n-5) &__point {
                background-color: $red;
            }

            &:nth-child(4n-4) &__point {
                background-color: $blue;
            }

            &__link {
                display: flex;
                align-items: center;
                gap: 15px;
            }

            &__point {
                width: 8px;
                height: 8px;
                border-radius: 2px;


            }
        
            &__text {
                @extend %text-overflow;
                color: $off-white;
                font-size: 16px;
            }
        
        }
    }
}

.new-workspace-form {

    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 270px;

    background-color: $off-white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: $light-shadow;
    outline: 1px solid $border;

    &__label {
        font-size: 14px;
        line-height: 14px;
        color: $primary-text;
        font-weight: 500;
    }

    &__input {
        background: $field;
        border: 1px solid $field-stroke;
        border-radius: 5px;

        font-size: 16px;
        padding: 10px;

        &:hover, &:focus {
            border: 1px solid $blue;
        }
    }

    &__button {
        background-color: $blue;
        color: $off-white;
        padding: 10px;
        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        svg {
            stroke: $off-white;
        }

    }
}

.priority-label {
    border-radius: 5px;
    height: 6px;
    width: 25px;

    &.--urgent {
        background-color: $red;
    }

    &.--high {
        background-color: $gold;
    }
}

.due-date {

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    
    &__text {
        font-size: 14px;
        line-height: 14px;
    }

    &__icon {
        stroke: $secondary-text;
    }

    &__divider {
        border: 0px;
        height: 1px;
        background-color: $border;
    }
}

.objective {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 20px;
    cursor: pointer;
    user-select: none;
    position: relative;
    z-index: 3;
    min-height: 80px;
    background-color: white;
    border-radius: 5px;

    box-shadow: $light-shadow;
    outline: 1px solid $border;

    transition: outline ease-in 200ms, box-shadow ease-in 200ms;

    &.--completed {
        opacity: 0.7;
        text-decoration: line-through;
    }

    &:hover{
        outline: 1px solid rgba(116, 116, 116, 0.2);
        box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
    }

    &__title {
        font-size: 14px;
        max-height: 150px;
        color: $secondary-text;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__options-button {

        display: flex;
        align-items: center;

        position: absolute;
        top: 10px;
        right: 10px;

        padding: 8px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid $border;

        transition: ease-in 100ms;

        &:hover {
            background-color: #f5f5f5;
            border: 1px solid #bdbdbd;
        }

        svg {
            fill: $secondary-text;
        }

    }

    &__data {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.options-menu {

    border-radius: 5px;
    outline: 1px solid $border;
    box-shadow: $light-shadow;
    background-color: white;
    padding: 10px 0px;
    min-width: 150px;

    &__item {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 10px;
        padding:6px 15px;
        font-size: 16px;
        line-height: 16px;
        width: 100%;

        &.--regular {
            color: $secondary-text;

            svg {
                stroke: $secondary-text;
            }
        }

        &.--warning {
            color: $red;

            svg {
                stroke: $red;
            }
        }

        &:hover {
            background-color: #f5f5f5;
        }
    }
}

.drag-overlay {
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;

    outline: 1px solid rgb(216, 216, 216);
    border-radius: 5px;
    z-index: 10;
    overflow: hidden;
    height: 100%;
}

.dragging {
    background-color: #e0e0e0;
    border-radius: 5px;
    outline: none;
    box-shadow: none;
    transition: box-shadow ease-in 0ms;
}

.category {
    z-index: 1;
    min-width: $objective-width;
    max-width: $objective-width;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 10px;

        z-index: 2;
        align-content: flex-start;
        overflow-y: auto;
        height: calc(100% - 60px);
    }

    &__header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 10px;
        align-items: center;

        &__title {
            font-weight: 500;
            font-size: 16px;
            padding-left: 6px;
            margin-right: auto;

            @extend %text-overflow;
        }

        &__add-button {

            padding: 8px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            
            svg {
                stroke: $secondary-text;
            }

            &:hover {
                background-color: $field-stroke;
            }
        }

        &__options-button {
            
            padding: 8px;
            display: flex;
            align-items: center;
            border-radius: 5px;

            svg {
                fill: $secondary-text;
            }

            &:hover {
                background-color: $field-stroke;
            }
        }

        .rename-container {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 10px;
        
            &__input {
                font-weight: 500;
                background-color: $field;
                width: 100px;
                flex: 2;
                border-radius: 5px;
                padding: 4.5px 6px;
                border: none;
                outline: 1px solid $field-stroke;
        
                &:hover, &:focus {
                    border: none;
                    outline: 1px solid rgb(189, 189, 189);
                }
            }
        
            &__button-wrapper {
                display: flex;
                align-items: center;
        
                &__button {
                    padding: 8px;
                    display: flex;
                    align-items: center;
                    border-radius: 5px;
                    
                    svg {
                        stroke: $secondary-text;
                    }
        
                    &:hover {
                        background-color: $field-stroke;
                    }
                }
            }
        }

    }
}

.new-objective {

    border: 1px solid $border;
    border-radius: 5px;
    box-shadow: $light-shadow;
    background-color: white;
    padding: 20px;

    &__form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__label {
            font-size: 14px;
            line-height: 14px;
            color: $primary-text;
            font-weight: 500;
        }
    
        &__input {
            font-size: 14px;
            padding: 10px;
            border-radius: 5px;
            width: 100%;
            border: 1px solid $field-stroke;
    
            &:hover, &:focus {
                border: 1px solid $blue;
            }
            
        }

        &__button {
            background-color: $blue;
            color: $off-white;
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            border: none;
        }
    }
}

.main {
    display: flex;
    height: 100%;
    width: 100%;
}

.board-header {

    grid-column: span 2;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: $header-height;
    background-color: white;
    border-bottom: 1px solid $border;
    padding: 20px 30px;
    align-items: flex-start;
    gap: 5px;

    &__options-button {
        padding: 8px;
        display: flex;
        align-items: center;
        border-radius: 5px;

        svg {
            stroke: $secondary-text;
        }

        &:hover {
            background-color: $field-stroke;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;

        &__current-user-wrapper {
            margin-left: auto;
        }
    }

    &__title {
        height: fit-content;
        font-size: 20px;
        font-weight: 500;
        max-width: 300px;
        height: 36px;

        @extend %text-overflow;
    }

    .rename-container {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 10px;

        &__input {
            font-weight: 500;
            max-width: 300px;
            border: none;
            font-size: 20px;
            padding: 0px 0px 6px 0px;

            box-shadow: 0px 1px 0px 0px $secondary-text;
    
            &:hover, &:focus {
                border: none;
                box-shadow: 0px 2px 0px 0px $secondary-text;
            }
        }
    
        &__button-wrapper {
            display: flex;
            align-items: center;
    
            &__button {
                padding: 8px;
                display: flex;
                align-items: center;
                border-radius: 5px;
                
                svg {
                    stroke: $secondary-text;
                }
    
                &:hover {
                    background-color: $field-stroke;
                }
            }
        }
    }
}

.header-navigation {
    display: flex;
    gap: 20px;
    
    &__link {
        font-size: 16px;
        color: $secondary-text;
        padding-bottom: 5px;

        &.--current {
            box-shadow: 0px 2px 0px 0px $blue;
        }
    }
}

.current-user {
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;

    &__icon {
        min-width: 32px;
        min-height: 32px;
        border-radius: 10px;
        background-color: $gold;
    
        &__char {
            font-size: 18px;
            text-align: center;
            line-height: 32px;
            color: white;
        }
    }

    &__name {
        font-size: 16px;
        align-self: center;
        color: $primary-text;
        max-width: 200px;

        @extend %text-overflow;
    }

}

.board {
    display: grid;

    grid-template-rows: $header-height calc(100% - $header-height);
    grid-template-columns: minmax(0, 1fr) auto; 

    width: calc(100% - #{$sidebar-width});
    height: 100%;
}

.board-content {

    grid-row: 2;
    grid-column: 1;

    width: 100%;

    height: 100%;
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    gap: 15px;
    padding: 10px 20px 10px 20px;
    background-color: $background;
    overflow-x: auto;
    overflow-y: hidden;
}

.new-category {

    &__button {
        font-weight: 500;
        padding: 5px 0px;
        margin: 10px;
        min-width: 270px;

        &:hover {
        border-radius: 5px;
        background-color: rgb(231, 231, 231);
        }
    }

    &__input:focus {
        height: 35px;
        width: 270px;
        margin: 10px;
        padding: 0px 10px;
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid rgba(82, 82, 82, 0.8);
    }
}

.select {
    position: relative;
    width: fit-content;
    max-width: 500px;
    user-select: none;

    &__control {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        font-size: 14px;
        color: $secondary-text;

        cursor: pointer;

        &.--active {
            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            stroke: $secondary-text;
        }
    }
    
    &__menu {
        position: absolute;
        z-index: 9999;
        margin-top: 10px;
        width: fit-content;
        border-radius: 5px;
        background-color: white;
        box-shadow: $light-shadow;
        outline: 1px solid $field-stroke;

        :first-child:hover {
            border-radius: 5px 5px 0px 0px;
        }

        :last-child:hover {
            border-radius: 0px 0px 5px 5px;
        }

        &__option {
            display: flex;
            align-items: center;
            position: relative;
            gap: 10px;
            padding: 7px 40px;
            cursor: pointer;
            font-size: 15px;
            white-space: nowrap;
            color: $secondary-text;
    
            &:hover {
                background-color: #f8f8f8;
            }

            svg {
                position: absolute;
                left: 11px;
                stroke: $secondary-text;
            }
        }
    }
}

.objective-details {

    display: flex;
    flex-direction: column;
    gap: 20px;

    grid-row: 2; 
    grid-column: 2;
    
    padding: 30px;
    height: 100%;
    width: 550px;
    overflow: scroll;

    background-color: white;
    border-left: 1px solid $border;
    box-shadow: $light-shadow;

    &__header {
        font-size: 18px;
        font-weight: 600;
    }

    &__divider {
        border: none;
        height: 1px;
        box-shadow: 0px 1px 0px 0px $border;
    }

    &__row {
        display: flex;
        gap: 35px;
        padding: 10px 0px;
        align-items: center;
    }

    &__column {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &__label {
        font-size: 14px;
        color: $secondary-text;
        font-weight: 500;
        width: fit-content;
        white-space: nowrap;
    }

    &__title-input {
        width: 100%;
        min-height: 100px;
        resize: none;
        padding: 15px;
    
        color: $primary-text;
        font-size: 16px;
        background-color: $field;
    
        border-radius: 3px;
        border: 1px solid $field-stroke;
    
        &:focus {
            outline: 1px solid $blue;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
    }

    &__description-input {
        width: 100%;
        min-height: 200px;
        resize: none;
        padding: 15px;
    
        color: $primary-text;
        font-size: 16px;
        background-color: $field;
    
        border-radius: 3px;
        border: 1px solid $field-stroke;
    
        &:focus {
            outline: 1px solid $blue;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
     }
}

.react-datepicker__tab-loop {
    position: relative;
}

.datepicker-input {
    display: flex;
    z-index: 5;
    max-width: 200px;
    align-items: flex-start;
    cursor: pointer;
    font-size: 14px;

}

.react-datepicker {
    border: 1px solid $border !important; 
    box-shadow: $light-shadow;
}

.date-picker {
    display: flex;
    flex-direction: column;

    &__clear-button {
        margin: 10px;
        padding: 5px 10px;
        width: fit-content;
        font-size: 14px;
        float: right;
        border-radius: 5px;
        cursor: pointer;

        color: white;
        background-color: $blue;

        &:hover {
            background-color: $dark-blue;
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px 10px 15px;

        &____current-month-label {
            width: fit-content;
        }
    
        &__change-month-button {
            height: 20px;
            width: 20px;
            padding: 0px;
            margin: 0px;
            outline: 1px solid #b8b8b8;
            background-color: white;
            border-radius: 5px;
            
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
    
            svg {
                stroke: $secondary-text;
            }
    
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }

    .react-datepicker__day--today {
        color: $secondary-text;
        font-weight: 400;
        outline: 1px solid $blue;
        border-radius: 5px;
    }

    .react-datepicker__day {
        font-size: 14px;
        color: $primary-text;

        &:hover {
            background-color: $border;
            color: $secondary-text;
            border-radius: 5px;
        }
    }
    
    .react-datepicker__day--keyboard-selected {
        font-size: 14px;
        color: white;
        background-color: $blue;
        border-radius: 5px;
        outline: none;

    }
    
    .react-datepicker__day--outside-month {
        font-size: 14px;
        color: $secondary-text;
    }
    
    .react-datepicker__day-name {
        font-size: 14px;
        color: $primary-text;
    }
    
    .react-datepicker__header{
        background-color: white;

        border: none;
        padding-bottom: 0px;
    }

}

.deleted-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - #{$header-height});
    padding: 15px;
    gap: 15px;
    height: 100%;

    background-color: $background;
    overflow-y: auto;

}

.deleted-objective {
    display: flex;
    width: auto;
    height: 100px;
    box-shadow: $light-shadow;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: white;
    align-items: center;

    &__text {
        max-height: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__options {
        display: flex;
        flex-direction: column;

        gap: 10px;
        margin-left: auto;

        &__button {
            width: 100px;
            border-radius: 3px;
            background-color: #eeeeee;
            cursor: pointer;
            
            &:hover {
                background-color: #dbdbdb;
            }
        }
    }
}

.modal-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

 .modal-contents {
    position: relative;
    width: fit-content;
    z-index: 8;
}

.modal-exit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 24px;
    width: 24px;
    z-index: 9;
}

.home {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.account-settings {

    display: flex;
    flex-direction: column;
    overflow: hidden;

    min-width: 500px;
    width: 50vw;
    max-width: 1000px;
    height: 90vh;
    

    &__header {
        background-color: white;
        border-bottom: 1px solid $border;
        padding: 40px 20px 20px 20px;
        
        border-radius: 5px 5px 0px 0px;
    }

    &__content {
        background-color: white;
        overflow-y: auto;
        padding: 20px;
        border-radius: 0px 0px 5px 5px;
        height: 100%;

        p {
            text-align: center;
            padding: 300px 0px;
        }
    }
}

.sign-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    gap: 20px;

    @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: flex-start;
    }

    &__form {
        padding: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }
 
    &__video {


        box-shadow: 0px 0px 0px 5px rgb(2, 12, 48);
        border-radius: 2.5% / 5%;

        width: 60%;
        height: auto;

        @media (max-width: 1024px) {
            width: 100%;
            height: auto;
        }

    }

}

.sign-label {
    font-size: 14px;
    font-weight: 500;
    color: $secondary-text;
}

.sign-input {
    border: none;
    background-color: $field;
    outline: 1px solid $field-stroke;
    padding: 10px;
    border-radius: 5px;

    &:hover {
        border: none;
        outline: 1px solid $blue;
    }

    &:focus {
        border: none;
        outline: 2px solid $blue;
    }

}

.sign-button {
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;

    background-color: $blue;
    color: white;

    border: none;

    &:hover {
        background-color: $dark-blue;
    }
}
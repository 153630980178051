@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.error-message {
  color: red;
}

.flex {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}

.col {
  flex-direction: column;
}

.gap-10 {
  gap: 10px;
}

.w-100 {
  min-width: 100%;
  max-width: 100%;
}

.p-10 {
  padding: 10px;
}

.w-250 {
  width: 250px;
}

* {
  box-sizing: border-box;
  margin: 0px;
  color: #5A5C6C;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

#root {
  height: 100vh;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
button:focus {
  outline: none;
}

input {
  outline: none;
}
input:focus, input:hover {
  outline: none;
  border: none;
}

a {
  text-decoration: none;
}

p {
  overflow-wrap: break-word;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  margin: 5px 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(190, 190, 190);
  border-radius: 50px;
}

.current-user__name, .board-header__title, .category__header__title, .workspaces__list__item__text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu {
  position: absolute;
  z-index: 9999;
}

.sidebar {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  height: 100%;
  max-width: 270px;
  min-width: 270px;
  background-color: #2B3E51;
  overflow-y: auto;
}
.sidebar::-webkit-scrollbar {
  width: 7px;
}
.sidebar::-webkit-scrollbar-thumb {
  background: #616385;
  border-radius: 50px;
}
.sidebar__navigation-item, .sidebar__account-section__logout {
  padding: 0px 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #FBFBFB;
}
.sidebar__navigation-item svg, .sidebar__account-section__logout svg {
  stroke: #FBFBFB;
}
.sidebar__logo-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 20px;
}
.sidebar__logo-section__minimise-button {
  display: flex;
  align-items: center;
}
.sidebar__logo-section__minimise-button svg {
  stroke: #FBFBFB;
}
.sidebar__navigation-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}
.sidebar__account-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}
.sidebar__account-section__logout {
  color: #FF5353;
}
.sidebar__account-section__logout svg {
  stroke: #FF5353;
}

.workspaces {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.workspaces__new-button {
  background-color: #6997EE;
  color: #FBFBFB;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.workspaces__new-button svg {
  stroke: #FBFBFB;
}
.workspaces__show-button {
  background-color: #21303F;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.workspaces__show-button svg {
  stroke: #FBFBFB;
}
.workspaces__show-button__span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #FBFBFB;
}
.workspaces__show-button__arrow--up {
  transform: rotate(180deg);
}
.workspaces__list {
  max-height: 250px;
  overflow-y: scroll;
  list-style: none;
  padding: 0px 20px;
}
.workspaces__list::-webkit-scrollbar {
  width: 7px;
}
.workspaces__list::-webkit-scrollbar-thumb {
  background: #616385;
  border-radius: 50px;
}
.workspaces__list__item {
  width: 100%;
  margin-bottom: 15px;
}
.workspaces__list__item:nth-child(4n-7) .workspaces__list__item__point {
  background-color: #FCB529;
}
.workspaces__list__item:nth-child(4n-6) .workspaces__list__item__point {
  background-color: #00AB65;
}
.workspaces__list__item:nth-child(4n-5) .workspaces__list__item__point {
  background-color: #FF5353;
}
.workspaces__list__item:nth-child(4n-4) .workspaces__list__item__point {
  background-color: #6997EE;
}
.workspaces__list__item__link {
  display: flex;
  align-items: center;
  gap: 15px;
}
.workspaces__list__item__point {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
.workspaces__list__item__text {
  color: #FBFBFB;
  font-size: 16px;
}

.new-workspace-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 270px;
  background-color: #FBFBFB;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  outline: 1px solid #E7E7E7;
}
.new-workspace-form__label {
  font-size: 14px;
  line-height: 14px;
  color: #27283A;
  font-weight: 500;
}
.new-workspace-form__input {
  background: #FBFBFB;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
}
.new-workspace-form__input:hover, .new-workspace-form__input:focus {
  border: 1px solid #6997EE;
}
.new-workspace-form__button {
  background-color: #6997EE;
  color: #FBFBFB;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.new-workspace-form__button svg {
  stroke: #FBFBFB;
}

.priority-label {
  border-radius: 5px;
  height: 6px;
  width: 25px;
}
.priority-label.--urgent {
  background-color: #FF5353;
}
.priority-label.--high {
  background-color: #FCB529;
}

.due-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.due-date__text {
  font-size: 14px;
  line-height: 14px;
}
.due-date__icon {
  stroke: #5A5C6C;
}
.due-date__divider {
  border: 0px;
  height: 1px;
  background-color: #E7E7E7;
}

.objective {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 20px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  z-index: 3;
  min-height: 80px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  outline: 1px solid #E7E7E7;
  transition: outline ease-in 200ms, box-shadow ease-in 200ms;
}
.objective.--completed {
  opacity: 0.7;
  text-decoration: line-through;
}
.objective:hover {
  outline: 1px solid rgba(116, 116, 116, 0.2);
  box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
}
.objective__title {
  font-size: 14px;
  max-height: 150px;
  color: #5A5C6C;
  overflow: hidden;
  text-overflow: ellipsis;
}
.objective__options-button {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #E7E7E7;
  transition: ease-in 100ms;
}
.objective__options-button:hover {
  background-color: #f5f5f5;
  border: 1px solid #bdbdbd;
}
.objective__options-button svg {
  fill: #5A5C6C;
}
.objective__data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options-menu {
  border-radius: 5px;
  outline: 1px solid #E7E7E7;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  background-color: white;
  padding: 10px 0px;
  min-width: 150px;
}
.options-menu__item {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  padding: 6px 15px;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
}
.options-menu__item.--regular {
  color: #5A5C6C;
}
.options-menu__item.--regular svg {
  stroke: #5A5C6C;
}
.options-menu__item.--warning {
  color: #FF5353;
}
.options-menu__item.--warning svg {
  stroke: #FF5353;
}
.options-menu__item:hover {
  background-color: #f5f5f5;
}

.drag-overlay {
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
  outline: 1px solid rgb(216, 216, 216);
  border-radius: 5px;
  z-index: 10;
  overflow: hidden;
  height: 100%;
}

.dragging {
  background-color: #e0e0e0;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  transition: box-shadow ease-in 0ms;
}

.category {
  z-index: 1;
  min-width: 270px;
  max-width: 270px;
}
.category__content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  z-index: 2;
  align-content: flex-start;
  overflow-y: auto;
  height: calc(100% - 60px);
}
.category__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px;
  align-items: center;
}
.category__header__title {
  font-weight: 500;
  font-size: 16px;
  padding-left: 6px;
  margin-right: auto;
}
.category__header__add-button {
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.category__header__add-button svg {
  stroke: #5A5C6C;
}
.category__header__add-button:hover {
  background-color: #e9e9e9;
}
.category__header__options-button {
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.category__header__options-button svg {
  fill: #5A5C6C;
}
.category__header__options-button:hover {
  background-color: #e9e9e9;
}
.category__header .rename-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.category__header .rename-container__input {
  font-weight: 500;
  background-color: #FBFBFB;
  width: 100px;
  flex: 2;
  border-radius: 5px;
  padding: 4.5px 6px;
  border: none;
  outline: 1px solid #e9e9e9;
}
.category__header .rename-container__input:hover, .category__header .rename-container__input:focus {
  border: none;
  outline: 1px solid rgb(189, 189, 189);
}
.category__header .rename-container__button-wrapper {
  display: flex;
  align-items: center;
}
.category__header .rename-container__button-wrapper__button {
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.category__header .rename-container__button-wrapper__button svg {
  stroke: #5A5C6C;
}
.category__header .rename-container__button-wrapper__button:hover {
  background-color: #e9e9e9;
}

.new-objective {
  border: 1px solid #E7E7E7;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  background-color: white;
  padding: 20px;
}
.new-objective__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.new-objective__form__label {
  font-size: 14px;
  line-height: 14px;
  color: #27283A;
  font-weight: 500;
}
.new-objective__form__input {
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #e9e9e9;
}
.new-objective__form__input:hover, .new-objective__form__input:focus {
  border: 1px solid #6997EE;
}
.new-objective__form__button {
  background-color: #6997EE;
  color: #FBFBFB;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  border: none;
}

.main {
  display: flex;
  height: 100%;
  width: 100%;
}

.board-header {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 115px;
  background-color: white;
  border-bottom: 1px solid #E7E7E7;
  padding: 20px 30px;
  align-items: flex-start;
  gap: 5px;
}
.board-header__options-button {
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.board-header__options-button svg {
  stroke: #5A5C6C;
}
.board-header__options-button:hover {
  background-color: #e9e9e9;
}
.board-header__row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}
.board-header__row__current-user-wrapper {
  margin-left: auto;
}
.board-header__title {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 20px;
  font-weight: 500;
  max-width: 300px;
  height: 36px;
}
.board-header .rename-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.board-header .rename-container__input {
  font-weight: 500;
  max-width: 300px;
  border: none;
  font-size: 20px;
  padding: 0px 0px 6px 0px;
  box-shadow: 0px 1px 0px 0px #5A5C6C;
}
.board-header .rename-container__input:hover, .board-header .rename-container__input:focus {
  border: none;
  box-shadow: 0px 2px 0px 0px #5A5C6C;
}
.board-header .rename-container__button-wrapper {
  display: flex;
  align-items: center;
}
.board-header .rename-container__button-wrapper__button {
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.board-header .rename-container__button-wrapper__button svg {
  stroke: #5A5C6C;
}
.board-header .rename-container__button-wrapper__button:hover {
  background-color: #e9e9e9;
}

.header-navigation {
  display: flex;
  gap: 20px;
}
.header-navigation__link {
  font-size: 16px;
  color: #5A5C6C;
  padding-bottom: 5px;
}
.header-navigation__link.--current {
  box-shadow: 0px 2px 0px 0px #6997EE;
}

.current-user {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}
.current-user__icon {
  min-width: 32px;
  min-height: 32px;
  border-radius: 10px;
  background-color: #FCB529;
}
.current-user__icon__char {
  font-size: 18px;
  text-align: center;
  line-height: 32px;
  color: white;
}
.current-user__name {
  font-size: 16px;
  align-self: center;
  color: #27283A;
  max-width: 200px;
}

.board {
  display: grid;
  grid-template-rows: 115px calc(100% - 115px);
  grid-template-columns: minmax(0, 1fr) auto;
  width: calc(100% - 270px);
  height: 100%;
}

.board-content {
  grid-row: 2;
  grid-column: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  gap: 15px;
  padding: 10px 20px 10px 20px;
  background-color: #FBFBFB;
  overflow-x: auto;
  overflow-y: hidden;
}

.new-category__button {
  font-weight: 500;
  padding: 5px 0px;
  margin: 10px;
  min-width: 270px;
}
.new-category__button:hover {
  border-radius: 5px;
  background-color: rgb(231, 231, 231);
}
.new-category__input:focus {
  height: 35px;
  width: 270px;
  margin: 10px;
  padding: 0px 10px;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid rgba(82, 82, 82, 0.8);
}

.select {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 500px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.select__control {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  font-size: 14px;
  color: #5A5C6C;
  cursor: pointer;
}
.select__control.--active svg {
  transform: rotate(180deg);
}
.select__control svg {
  stroke: #5A5C6C;
}
.select__menu {
  position: absolute;
  z-index: 9999;
  margin-top: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  outline: 1px solid #e9e9e9;
}
.select__menu :first-child:hover {
  border-radius: 5px 5px 0px 0px;
}
.select__menu :last-child:hover {
  border-radius: 0px 0px 5px 5px;
}
.select__menu__option {
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
  padding: 7px 40px;
  cursor: pointer;
  font-size: 15px;
  white-space: nowrap;
  color: #5A5C6C;
}
.select__menu__option:hover {
  background-color: #f8f8f8;
}
.select__menu__option svg {
  position: absolute;
  left: 11px;
  stroke: #5A5C6C;
}

.objective-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-row: 2;
  grid-column: 2;
  padding: 30px;
  height: 100%;
  width: 550px;
  overflow: scroll;
  background-color: white;
  border-left: 1px solid #E7E7E7;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
}
.objective-details__header {
  font-size: 18px;
  font-weight: 600;
}
.objective-details__divider {
  border: none;
  height: 1px;
  box-shadow: 0px 1px 0px 0px #E7E7E7;
}
.objective-details__row {
  display: flex;
  gap: 35px;
  padding: 10px 0px;
  align-items: center;
}
.objective-details__column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.objective-details__label {
  font-size: 14px;
  color: #5A5C6C;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
}
.objective-details__title-input {
  width: 100%;
  min-height: 100px;
  resize: none;
  padding: 15px;
  color: #27283A;
  font-size: 16px;
  background-color: #FBFBFB;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
}
.objective-details__title-input:focus {
  outline: 1px solid #6997EE;
}
.objective-details__title-input::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.objective-details__description-input {
  width: 100%;
  min-height: 200px;
  resize: none;
  padding: 15px;
  color: #27283A;
  font-size: 16px;
  background-color: #FBFBFB;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
}
.objective-details__description-input:focus {
  outline: 1px solid #6997EE;
}
.objective-details__description-input::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.react-datepicker__tab-loop {
  position: relative;
}

.datepicker-input {
  display: flex;
  z-index: 5;
  max-width: 200px;
  align-items: flex-start;
  cursor: pointer;
  font-size: 14px;
}

.react-datepicker {
  border: 1px solid #E7E7E7 !important;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
}

.date-picker {
  display: flex;
  flex-direction: column;
}
.date-picker__clear-button {
  margin: 10px;
  padding: 5px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  float: right;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: #6997EE;
}
.date-picker__clear-button:hover {
  background-color: #003054;
}
.date-picker__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
}
.date-picker__header____current-month-label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.date-picker__header__change-month-button {
  height: 20px;
  width: 20px;
  padding: 0px;
  margin: 0px;
  outline: 1px solid #b8b8b8;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.date-picker__header__change-month-button svg {
  stroke: #5A5C6C;
}
.date-picker__header__change-month-button:hover {
  background-color: #f0f0f0;
}
.date-picker .react-datepicker__day--today {
  color: #5A5C6C;
  font-weight: 400;
  outline: 1px solid #6997EE;
  border-radius: 5px;
}
.date-picker .react-datepicker__day {
  font-size: 14px;
  color: #27283A;
}
.date-picker .react-datepicker__day:hover {
  background-color: #E7E7E7;
  color: #5A5C6C;
  border-radius: 5px;
}
.date-picker .react-datepicker__day--keyboard-selected {
  font-size: 14px;
  color: white;
  background-color: #6997EE;
  border-radius: 5px;
  outline: none;
}
.date-picker .react-datepicker__day--outside-month {
  font-size: 14px;
  color: #5A5C6C;
}
.date-picker .react-datepicker__day-name {
  font-size: 14px;
  color: #27283A;
}
.date-picker .react-datepicker__header {
  background-color: white;
  border: none;
  padding-bottom: 0px;
}

.deleted-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 115px);
  padding: 15px;
  gap: 15px;
  height: 100%;
  background-color: #FBFBFB;
  overflow-y: auto;
}

.deleted-objective {
  display: flex;
  width: auto;
  height: 100px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  padding: 10px 20px;
  border-radius: 5px;
  background-color: white;
  align-items: center;
}
.deleted-objective__text {
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deleted-objective__options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: auto;
}
.deleted-objective__options__button {
  width: 100px;
  border-radius: 3px;
  background-color: #eeeeee;
  cursor: pointer;
}
.deleted-objective__options__button:hover {
  background-color: #dbdbdb;
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-contents {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 8;
}

.modal-exit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  position: absolute;
  right: 10px;
  top: 10px;
  height: 24px;
  width: 24px;
  z-index: 9;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.account-settings {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 500px;
  width: 50vw;
  max-width: 1000px;
  height: 90vh;
}
.account-settings__header {
  background-color: white;
  border-bottom: 1px solid #E7E7E7;
  padding: 40px 20px 20px 20px;
  border-radius: 5px 5px 0px 0px;
}
.account-settings__content {
  background-color: white;
  overflow-y: auto;
  padding: 20px;
  border-radius: 0px 0px 5px 5px;
  height: 100%;
}
.account-settings__content p {
  text-align: center;
  padding: 300px 0px;
}

.sign-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 20px;
}
@media (max-width: 1024px) {
  .sign-page {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.sign-page__form {
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.sign-page__video {
  box-shadow: 0px 0px 0px 5px rgb(2, 12, 48);
  border-radius: 2.5%/5%;
  width: 60%;
  height: auto;
}
@media (max-width: 1024px) {
  .sign-page__video {
    width: 100%;
    height: auto;
  }
}

.sign-label {
  font-size: 14px;
  font-weight: 500;
  color: #5A5C6C;
}

.sign-input {
  border: none;
  background-color: #FBFBFB;
  outline: 1px solid #e9e9e9;
  padding: 10px;
  border-radius: 5px;
}
.sign-input:hover {
  border: none;
  outline: 1px solid #6997EE;
}
.sign-input:focus {
  border: none;
  outline: 2px solid #6997EE;
}

.sign-button {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: #6997EE;
  color: white;
  border: none;
}
.sign-button:hover {
  background-color: #003054;
}/*# sourceMappingURL=styles.css.map */